<template>
  <v-row class="pr-0 align-center justify-space-between">
    <v-col cols="2">
      <v-container fluid class="pa-5">
        <v-img :src="logo"
                alt="logo"
                max-height="53"
              >
        </v-img>
      </v-container>
    </v-col>

    <v-col cols="4">
      <span class="ocre--text headline">Sistema de Control de Asistencia</span>
    </v-col>

    <v-col cols="6" class="d-flex align-center justify-end pr-0">
     <!--  <v-btn large class="mr-1" icon  :disabled="($route.name == 'Unauthorized' || $route.name == 'Authenticate')">
        <v-icon large color="dark">mdi-cog</v-icon>
      </v-btn> -->

      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-icon large color="dark">mdi-account-circle</v-icon>
           <!--  <v-avatar size="30">
                <v-img :src="perfil"
                    alt="perfil"
                ></v-img>
            </v-avatar> -->
            <span class="ml-1 dark--text">{{ userName }}</span>
            <!-- cerrar sesion -->
            <v-btn icon :disabled="($route.name == 'Unauthorized' || $route.name == 'Authenticate')">
              <v-icon color="dark"  class="title-menu">mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </template>

        <v-list dense>
          <v-list-item-group color="primary">
            <v-list-item link v-for="(item, i) in items" :key="i" :to="item.to" >
                <v-list-item-icon>
                    <v-icon color="dark">
                        {{item.icon}}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        <span class="dark--text">{{ item.title }}</span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>


export default {
  props: ['drawer'],
  name: "HeaderApp",
  data() {
    return {
      logo: process.env.VUE_APP_URL_YO + this.$store.state.user.Emp_Imagen,
      perfil: require('@/assets/perfil/laura.jpg'),
      items: [
        { title: "Cerrar Sesión", to: '/logout', icon: 'mdi-logout-variant' }
      ],
    };
  },
  computed:{
    userName() {
      return this.$store.state.user.name;
    },
  },
  methods: {
  },
};
</script>

