import Vue from 'vue';
import Vuetify from 'vuetify/lib';


Vue.use(Vuetify);

export default new Vuetify({
  icons:{
    iconfont:"mdi"
  },
  theme: {
    themes: {
      light: {
        primary: '#E5C646', //naranja
        ocre: '#C4AA41',
        secondary: '#ffffff', //blanco
        dark: '#4A4A4A',
       
      },
      dark: {
        primary: '#E5C646', //naranja
        secondary: '#4A4A4A', //negro
      },
    }
  }
});
