import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import router from '../router'
//import moment from "moment";



Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  getters : {
    isLoggedIn: state => !!state.user.authToken,
    authStatus: state => state.user.loggedInStatus,
    coloresChart: state => state.filtros.valorSwitchTraducido,
  },
  state: {
    snackbar:{show:false, text:"", color:"green"},
    loadPage : { show: false, message: 'Cargando...' },
    user: {
      Usu_Id: '',
      Emp_Id:'4',
      Emp_Nombre: '',
      Emp_Imagen: '',
      name: '',
      loggedInStatus: false,
      authToken: '',
      Geopop_Emp_Id: null,
      Geopop_Per_Id: null,
      Per_Id: '',
      New_EmpId: '',
    },
    rules: {
      min: v => v.length >= 8 || 'Min 8 caracteres',
      min1: v => v.length >= 1 || 'Este campo es Requerido',
      required: v => !!v || 'Este dato es Requerido',
      requiredMultiple: v => v.length > 0 || 'Este dato es Requerido',
    },
    filtros: {
      empresa: 0,
      supervisor: 0,
      operario: 0,
      rango: 4,
      fechaDesde: "",
      fechaHasta: "",
      todasLasFichadas: 0,
      valorSwitch: null,
      valorSwitchTraducido: [
        '#05E462',
        '#DD4733',
        '#B65930',
        '#F5DF4E',
        '#0172B6',
        '#373948',
        '#949597',
        '#F0E1CE',
        '#E0B58A',
        '#9B8A52',
        '#A1DBA9',
        '#EC8A7F',
        '#00A070',
        '#936BA7',
        '#936BA7',],
    },
  },
  mutations: {
    changeSwitch: (state) => {
      state.filtros.valorSwitch = !state.filtros.valorSwitch;
      console.log("cambio a "+state.filtros.valorSwitch)
      if (!state.filtros.valorSwitch) {
        state.filtros.valorSwitchTraducido = [
          '#05E462',
          '#DD4733',
          '#B65930',
          '#F5DF4E',
          '#0172B6',
          '#373948',
          '#949597',
          '#F0E1CE',
          '#E0B58A',
          '#9B8A52',
          '#A1DBA9',
          '#EC8A7F',
          '#00A070',
          '#936BA7',
          '#936BA7',]
      } else {

        state.filtros.valorSwitchTraducido = [
          '#E5C646',
          '#EAD26E',
          '#EFDE96',
          '#F4E9BD',
          '#F9F5E5',
          '#3F3B2E',
          '#605733',
          '#817338',
          '#A38E3C',
          '#C4AA41',
          '#EAD26E',
          '#EFDE96',
          '#F4E9BD',
          ]
      }
    },
    asignColor: (state) => {
      if (!state.filtros.valorSwitch) {
        state.filtros.valorSwitchTraducido = [
          '#05E462',
          '#DD4733',
          '#B65930',
          '#F5DF4E',
          '#0172B6',
          '#373948',
          '#949597',
          '#F0E1CE',
          '#E0B58A',
          '#9B8A52',
          '#A1DBA9',
          '#EC8A7F',
          '#00A070',
          '#936BA7',
          '#936BA7',]
      } else {

        state.filtros.valorSwitchTraducido = [
          '#E5C646',
          '#EAD26E',
          '#EFDE96',
          '#F4E9BD',
          '#F9F5E5',
          '#3F3B2E',
          '#605733',
          '#817338',
          '#A38E3C',
          '#C4AA41',
          '#EAD26E',
          '#EFDE96',
          '#F4E9BD',
          ]
      }
    },
    setFiltro: (state, data) => {
      state.filtros[data.type] = data.val;
    },
    setGeopopId: (state, data) => {
      state.user.Geopop_Emp_Id = data.Emp_Id;
      state.user.Geopop_Per_Id = data.Per_Id;
    },
    addWebToken: function (state, data) {
      state.user.authToken = data.Use_Token;
      state.user.Usu_Id = data.Usu_Id;
      state.user.Emp_Id = data.Emp_Id;
      state.user.Emp_Nombre = data.Emp_Data.Emp_Nombre;
      state.user.Emp_Imagen = data.Emp_Data.Emp_Imagen;
      state.user.Usu_TieneClientes = data.Usu_TieneClientes;
      state.user.name = data.Usu_Nombre + ' ' + data.Usu_Apellido;
      state.user.loggedInStatus = true;
    },
    removeWebToken: function (state) {
      state.user.authToken = '';
      state.user.Usu_Id = '';
      state.user.Emp_Id = '';
      state.user.Emp_Nombre = '';
      state.user.Emp_Imagen = '';
      state.user.name = '';
      state.user.loggedInStatus = false;
      state.user.Geopop_Emp_Id = '';
      state.user.Geopop_Per_Id = '';
    },
  },
  actions: {
    login: function (context, data) {
      return Vue.prototype.$http.post(process.env.VUE_APP_LOGIN_URI + 'login/by-token', { Use_Token: data.token, Usu_Id: data.usu_id})

        .then(function (response) {

          if (response.data.status === 'ok') {
            context.commit('addWebToken', response.data.data); // pass the webtoken as payload to the mutation
            console.log(response.data.data);
            router.push({
                path: '/'
              });
          }
          return response.data;
        });
    },
    logout: function (context) {
      context.commit('removeWebToken');
      router.push({
          path: '/',
      });
    }
  }
})

export default store;