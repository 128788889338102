import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
/* import VChart from 'v-chart'; */


Vue.use(VueRouter);
/* Vue.use(VChart); */



const routes = [
  
  {
    path: "/auth/:token/:usu_id",
    name: "Authenticate",
    props: true,
    component: () => import("../views/access/Authenticate.vue")
  },
  {
    path: "/notfound",
    name: "NotFound",
    component: () => import("../views/access/NotFound.vue")
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("../views/access/Unauthorized.vue")
  },
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/Home.vue"),
    props: true,
  },
  {
    path: "/bar",
    name: "bar",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/controlPanel/bar.vue"),
    props: true,
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
  },
];

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
    //valida si la route existe
    if (to.matched.length > 0) {//si existe

      //verifica si la route requiere login
      if(to.matched.some(record => record.meta.requiresAuth)) {//si requiere login
        if (store.getters.isLoggedIn) {
          next()
          return
        } else {
          router.push({
            name: 'Unauthorized'
          });
        }
      } else {//sino requiere login
        next()
      }
    } else {//sino existe
      router.push({
        name: 'NotFound'
      });
    }
  })

export default router;
