var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto card-nav",attrs:{"height":"100%","width":"256"}},[_c('v-navigation-drawer',{attrs:{"permanent":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.logo}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.empresa))])],1)],1)]},proxy:true}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.itemsMenu),function(item,index){return _c('div',{key:'mn'+index},[(item.submenus.length == 0)?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"exact":"","router":"","to":item.to,"color":"primary"}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"pa-0"},[_c('v-icon',{staticClass:"primary--text text--darken-1"},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',{staticClass:"primary--text text--darken-1"},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])]):_c('div',[_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-action',_vm._g(_vm._b({staticClass:"pa-0"},'v-list-item-action',attrs,false),on),[_c('v-icon',{staticClass:"primary--text text--darken-1"},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',_vm._g(_vm._b({staticClass:"primary--text text--darken-1"},'v-list-item-content',attrs,false),on),[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.submenus),function(option,index){return _c('v-list-item',{key:'smn'+index,attrs:{"router":"","exact":"","to":option.to}},[_c('v-tooltip',{attrs:{"right":"","bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-action',_vm._g(_vm._b({},'v-list-item-action',attrs,false),on),[_c('v-icon',{staticClass:"primary--text text--darken-1",attrs:{"x-small":""}},[_vm._v(_vm._s(option.icon))])],1),_c('v-list-item-content',_vm._g(_vm._b({staticClass:"primary--text text--darken-1"},'v-list-item-content',attrs,false),on),[_c('v-list-item-title',{domProps:{"textContent":_vm._s(option.title)}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(option.title))])])],1)}),1)],1)],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }